import React, { PureComponent, createRef } from "react";
import Carousel from "react-elastic-carousel";

class TrustedBy extends PureComponent {
  constructor(props) {
    super(props);
    this.catouselRefMD = createRef();
    this.catouselRefLG = createRef();
    this.catouselRefXL = createRef();
    this.catouselRefSM = createRef();
    this.catouselRefXS = createRef();
    this.resetTimeout = null;
  }

  onNextStart = (currentItem, nextItem, element) => {
    if (currentItem.index === nextItem.index) {
      element?.current?.goTo(0);
    }
  };

  onPrevStart = (currentItem, nextItem, element) => {
    if (currentItem.index === nextItem.index) {
      element?.current?.goTo(this.props.data.length);
    }
  };

  onNextEnd = ({ index }, element) => {
    clearTimeout(this.resetTimeout);
    this.resetTimeout = setTimeout(() => {
      element?.current?.goTo(0);
    }, 4000);
  };
  onMouseEnter = (ref) => {
    if (ref && ref.current) {
      ref.current.stopAutoPlay();
    }
  };

  onMouseLeave = (ref) => {
    if (ref && ref.current) {
      ref.current.startAutoPlay();
    }
  };

  trustedCard = (trusted, index) => (
    <div
      className="trusted-card d-flex flex-column align-items-center justify-content-center"
      title={trusted.name}
      key={index}
    >
      <div className="d-flex flex-column">
        <img
          src={trusted.image}
          className="w-100 h-auto trusted-by-img"
          alt={trusted.alt}
          style={{ maxHeight: trusted.height }}
          loading="lazy"
        />
      </div>
    </div>
  );

  render() {
    return (
      <>
        
        <div className="trusted-container py-md-3 px-md-5 py-5 px-2">
        <div className="star-container col py-md-3 px-md-5 py-3 d-flex justify-content-center align-items-center flex-md-row flex-column">
                    <div className="d-md-inline-flex d-flex flex-column justify-content-center align-items-center py-3 px-5">
                        <div className="stars">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star-half-stroke" />
                        </div>
                        <a href="https://www.capterra.com/p/265126/KonfHub/" target="_blank" rel="noreferrer">
                            <div className="star-title">4.8/5 <b>Capterra</b></div>
                        </a>
                    </div>
                    <div className="d-md-inline-flex d-flex flex-column justify-content-center align-items-center py-3 px-5">
                        <div className="stars">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star-half-stroke" />
                        </div>
                        <a href="https://www.softwareadvice.com/ticketing/konfhub-profile/reviews/" target="_blank" rel="noreferrer">
                            <div className="star-title">4.7/5 <b>Software Advice</b></div>
                        </a>
                    </div>
                    <div className="d-md-inline-flex d-flex flex-column justify-content-center align-items-center py-3 px-5">
                        <div className="stars">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star-half-stroke" />
                        </div>
                        <a href="https://www.getapp.com/customer-management-software/a/konfhub/" target="_blank" rel="noreferrer">
                            <div className="star-title">4.8/5 <b>GetApp</b></div>
                        </a>
                    </div>
        </div>
          <div className="row g-0">
            <div className="col py-md-5">
              <h2 className="trusted-title text-center">
                Trusted by 100+ Companies
              </h2>
            </div>
          </div>
          <div className="row g-0 py-3 py-md-0 pb-md-5">
            <div className="col d-none d-md-block d-lg-none d-xl-none">
              <Carousel
                ref={this.catouselRefMD}
                itemsToShow={4}
                isLTR={true}
                enableAutoPlay={true}
                autoPlaySpeed={3000}
                stopAutoPlayOnHover={true}
                onNextStart={(currentItem, nextItem) =>
                  this.onNextStart(currentItem, nextItem, this.catouselRefMD)
                }
                onPrevStart={(currentItem, nextItem) =>
                  this.onPrevStart(currentItem, nextItem, this.catouselRefMD)
                }
                onNextEnd={({ index }) =>
                  this.onNextEnd({ index }, this.catouselRefMD)
                }
                disableArrowsOnEnd={false}
                disableArrowsOnStart={false}
                onMouseEnter={() => this.onMouseEnter(ref)}
                onMouseLeave={() => this.onMouseLeave(ref)}
              >
                {this.props.data.map(this.trustedCard)}
              </Carousel>
            </div>
            <div className="col d-none d-md-none d-lg-block d-xl-none">
              <Carousel
                ref={this.catouselRefLG}
                itemsToShow={5}
                isLTR={true}
                enableAutoPlay={true}
                autoPlaySpeed={3000}
                stopAutoPlayOnHover={true}
                onNextStart={(currentItem, nextItem) =>
                  this.onNextStart(currentItem, nextItem, this.catouselRefLG)
                }
                onPrevStart={(currentItem, nextItem) =>
                  this.onPrevStart(currentItem, nextItem, this.catouselRefLG)
                }
                onNextEnd={({ index }) =>
                  this.onNextEnd({ index }, this.catouselRefLG)
                }
                disableArrowsOnEnd={false}
                disableArrowsOnStart={false}
                onMouseEnter={() => this.onMouseEnter(ref)}
                onMouseLeave={() => this.onMouseLeave(ref)}
              >
                {this.props.data.map(this.trustedCard)}
              </Carousel>
            </div>
            <div className="col d-none d-md-none d-lg-none d-xl-block">
              <Carousel
                ref={this.catouselRefXL}
                itemsToShow={5}
                isLTR={true}
                enableAutoPlay={true}
                autoPlaySpeed={3000}
                stopAutoPlayOnHover={true}
                onNextStart={(currentItem, nextItem) =>
                  this.onNextStart(currentItem, nextItem, this.catouselRefXL)
                }
                onPrevStart={(currentItem, nextItem) =>
                  this.onPrevStart(currentItem, nextItem, this.catouselRefXL)
                }
                onNextEnd={({ index }) =>
                  this.onNextEnd({ index }, this.catouselRefXL)
                }
                disableArrowsOnEnd={false}
                disableArrowsOnStart={false}
                onMouseEnter={() => this.onMouseEnter(ref)}
                onMouseLeave={() => this.onMouseLeave(ref)}
              >
                {this.props.data.map(this.trustedCard)}
              </Carousel>
            </div>
            <div className="col d-block d-sm-none d-md-none d-lg-none d-xl-none">
              <Carousel
                ref={this.catouselRefXS}
                itemsToShow={1}
                isLTR={true}
                enableAutoPlay={true}
                autoPlaySpeed={3000}
                stopAutoPlayOnHover={true}
                onNextStart={(currentItem, nextItem) =>
                  this.onNextStart(currentItem, nextItem, this.catouselRefXS)
                }
                onPrevStart={(currentItem, nextItem) =>
                  this.onPrevStart(currentItem, nextItem, this.catouselRefXS)
                }
                onNextEnd={({ index }) =>
                  this.onNextEnd({ index }, this.catouselRefXS)
                }
                disableArrowsOnEnd={false}
                disableArrowsOnStart={false}
                onMouseEnter={() => this.onMouseEnter(ref)}
                onMouseLeave={() => this.onMouseLeave(ref)}
              >
                {this.props.data.map(this.trustedCard)}
              </Carousel>
            </div>
            <div className="col d-none d-sm-block d-md-none d-lg-none d-xl-none">
              <Carousel
                ref={this.catouselRefSM}
                itemsToShow={3}
                isLTR={true}
                enableAutoPlay={true}
                autoPlaySpeed={3000}
                stopAutoPlayOnHover={true}
                onNextStart={(currentItem, nextItem) =>
                  this.onNextStart(currentItem, nextItem, this.catouselRefSM)
                }
                onPrevStart={(currentItem, nextItem) =>
                  this.onPrevStart(currentItem, nextItem, this.catouselRefSM)
                }
                onNextEnd={({ index }) =>
                  this.onNextEnd({ index }, this.catouselRefSM)
                }
                disableArrowsOnEnd={false}
                disableArrowsOnStart={false}
                onMouseEnter={() => this.onMouseEnter(ref)}
                onMouseLeave={() => this.onMouseLeave(ref)}
              >
                {this.props.data.map(this.trustedCard)}
              </Carousel>
            </div>
          </div>
        </div>
        <style>{`
          .trusted-container {
            background: #white;
          }
          .star-container {
            margin-top: 40px;
          }
          .stars i {
    margin: 0 5px;
}

.star-title {
    font-family: 'Nunito';
    color: #572148;
}

          .trusted-container .trusted-title {
            color: #572148;
            font-weight: 700;
            font-size: 22px;
            font-family: 'Nunito';
          }

          .trusted-container .trusted-sub-title {
            font-family: 'Nunito';
            font-weight: 400;
            font-size: 22px;
            color: #572148;
          }

          .trusted-container .trusted-by-img {
            filter: grayscale(100%);
          }

          .trusted-container .trusted-by-img:hover {
            filter: grayscale(0%);
          }
        `}</style>
      </>
    );
  }
}

export default TrustedBy;